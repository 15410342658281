import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_BLOG } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/articles/Editor';
import ArticleTagsList from 'Components/admin/articleTags/List';
import ArticleCommentList from 'Components/admin/articleComments/List';
import ArticleCategoryList from 'Components/admin/articleCategories/List';

export const TAB_DATA = 'data';
export const TAB_ARTICLE_TAGS = 'articleTags';
export const TAB_ARTICLE_COMMENTS = 'articleComments';
export const TAB_ARTICLE_CATEGORIES = 'articleCategories';

export default class AdminArticleManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        article: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { location, history, article } = this.props;

        return (
            <StyledComponent
                className="admin-article-manage"
                styles={require('./styles')}
            >
                {article && (
                    <PageContent
                        breadcrumbs={[{
                            to: withVariables(ADMIN_BLOG.path, {}, {}),
                            label: 'Lista artykułów',
                        }]}
                    >
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Edycja artykułu"
                            tabs={[{
                                key: TAB_DATA,
                                label: 'Dane',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={article.data}
                                    />
                                ),
                            }, {
                                key: TAB_ARTICLE_TAGS,
                                label: 'Tagi',
                                children: (
                                    <ArticleTagsList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            articleId: article.data?.id
                                        }}
                                    />
                                ),
                            }, {
                                key: TAB_ARTICLE_COMMENTS,
                                label: 'Komentarze',
                                children: (
                                    <ArticleCommentList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            articleId: article.data?.id
                                        }}
                                    />
                                )
                            }, {
                                key: TAB_ARTICLE_CATEGORIES,
                                label: 'Kategorie',
                                children: (
                                    <ArticleCategoryList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            articleId: article.data?.id
                                        }}
                                    />
                                )
                            }]}
                        />
                    </PageContent>
                )}
            </StyledComponent>
        );
    }
}